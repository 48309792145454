import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import "./user-management.css";
import CForm from "../libs/cform/cform";
import Config from "../../config";
import * as userActions from "../../redux/user/user_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as memberActions from "../../redux/member/member_actions";
import * as managementActions from "../../redux/management/management_actions";
import ReactLoading from "react-loading";
import Loader from '../loader/loader';
import Select from 'react-select';

import Header from '../header/header';
import Loaderview from "../roster/loaderview";

class UserManagementPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamList: null,
      createTeam: false,
      datas: null,
      userId: "",
      idTeam: null,
      memberList: null,
      companyList:  [],
      companyTeamList: null,
      userRole: "",
      isRemoveMember: false,
      removeMemberName:"",
      memberId: null,
      memberRole: null,
      isAddMember: false,
      selectedRole:null,
      isLoading:true,
      currentPage: 1,
      itemsPerPage: 10,
      totalPages:"",
      activeMembers:null,
      totalMembers:null,
      emptyTeam:false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.isSubmit=false;
    this.dataForm = [
      {
        type: "TEXT",
        key: "name",
        id: "name",
        placeholder: "Name",
        def_value: "",
        // def_value:'TrangHoang',
        validator: "isName",
        feedback: false,
      },
      {
        type: "TEXT",
        key: "email",
        id: "email",
        placeholder: "Email",
        validator: "isEmail",
        def_value: "",
        // def_value:'tranghoang1001@gmail.com',
        feedback: false,
      }, 
      {
        type: 'TEXT',
        key: "title",
        id: "title",
        placeholder:"Job Title",
        def_value: '',
        // def_value:'123456',
        validator: 'isRequired',
        feedback: false,
    }    
    ];
  }
  componentDidMount() {
    this._init(1);
  }

  _init = (page) => {
    this.setState({
      isLoading: true
  });
    const { searchQuery } = this.state;
    console.log("======>this.props", this.props);
    let checkLogin = localStorage.getItem("LOGIN");
    checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
    let companyId = checkLogin.company.id;
    console.log("Company ID", companyId);

    this.props.managementActions.getMemberTeamListManagement(
      { companyId: companyId, page: page, perPage: this.state.itemsPerPage,searchQuery: searchQuery },
      (error, data) => {
        if(error){
          console.log('Member collection failed',error);
        }
        console.log('MemberDetails Response',data);
        this.setState({ companyList: data.userCollectionInfo.userCollection });
        this.setState({ companyTeamList: data.userCollectionInfo.teams });
        this.setState({ totalPages:data.totalPages})
        this.setState({activeMembers:data.userCollectionInfo.activeMember})
        this.setState({totalMembers:data.userCollectionInfo.totalMembers})        
        this.setState({
          isLoading: false
      });
      }
    );
  };

  setFormRef = (ref) => {
    this.formRef = ref;
  };

  handleRoleChange = (event) => {
    this.setState({ selectedRole: event.target.value });
  };

  openModal = (member) => {
    
    this.setState({ isRemoveMember: true });
    this.setState({ memberId: member.user.id });
    this.setState({ removeMemberName: member.user.fullName });
  };
  closeModal = () => {
    this.setState({ isRemoveMember: false });
  };

  handleOpen = () => {
    this.setState({ isAddMember: true });
  }

  handleClose = () => {
    this.setState({ isAddMember: false });
    this._timer = setTimeout(() => {
      clearTimeout(this._timer);
      if (this.props.handleClose) this.props.handleClose();
    }, 300);
  };

  _removeMember = (memberId) => {
    console.log('member details',memberId);
    this.setState({
      isLoading: true
  });

  this.props.managementActions.removeMemberManagement({ id: memberId }, (err, data) => {
    if (err) {
      Config.alertMess(err);
    } else {
      // Remove the member from the companyList in the state
      this.closeModal();
      const { currentPage } = this.state;
      this._init(currentPage);      
    }
  });
   
  };

  _addMember = (cb) => {
    if(this.isSubmit) return;
    let checkLogin = localStorage.getItem("LOGIN");
    checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
    this.setState({
      isLoading: true
  });
    let companyId = checkLogin.company.id;
    let check = this.refs["CForm"].formValidate();
    let dt = this.refs["CForm"].formValue(); 
    let userRole=this.state.userRole;  
    console.log(userRole,'ussssssssssssss');
    if(!check || userRole=='') {
      this.setState({messageEvent: '* Fill out all fields or input correct details to the fields and try again.'});
      setTimeout(()=>{
          this.setState({messageEvent: '',isLoading:false});
          this.isSubmit = false;
      },5000);
      return;
  }
    if (check && userRole) {
      console.log('Success');

      let params = {
        name: dt.name.value,
        title: dt.title.value,
        email: dt.email.value,
        team: userRole,
    };

    this.props.teamActions.inviteMember(params, (err, data)=> {
      if(data){
        this.setState({messageEvent: '',isLoading:false});
        this.setState({ isAddMember: false });
        this.isSubmit = false;
        const { currentPage } = this.state;
        this._init(currentPage);        
      }
      else{
        this.setState({messageInviteMember:err.message});
        this._timer = setTimeout(()=>{
          this.setState({
            messageInviteMember:'',
              isLoading: false              
          });
      },5000);              
        return;
    }
  });
  

    }
  };

  onChangeSelect = (e, idx) => {
    this.setState({ userRole: e.value });
};

handlePageChange = (pageNumber) => {
  this.setState({ currentPage: pageNumber });
  this._init(pageNumber); // Fetch data for the new page
};

handlePreviousPage = () => {
  const { currentPage } = this.state;
  if (currentPage > 1) {
    const Page = currentPage - 1;
    this.setState({ currentPage: Page }, () => {
      console.log("newpage",Page);
      this._init(Page);
    });
  }
};

handleNextPage = () => { 
  const { currentPage,totalPages, companyList } = this.state;
  let isDisable = companyList.length < 10 && currentPage + 1;  
  if (!isDisable && currentPage < totalPages) {
    const Page = currentPage + 1;
    this.setState({ currentPage: Page }, () => {
      console.log("newpage",Page);
      this._init(Page);
    });
  }
};


renderPageNumbers() {
  const { currentPage,totalPages,companyList } = this.state;

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    let isDisable = false;    
    if (currentPage && companyList.length < 10) {      
      if (i !== currentPage - 1 && i !== currentPage && totalPages !== currentPage){
        isDisable = true;
      }      
    }
    pageNumbers.push(
      <div
        key={i}
        className={`page-numbers ${currentPage === i ? "active" : ""} ${isDisable? "disabled" : ""}`}
        onClick={() =>!isDisable && this.handlePageChange(i)}
      >
        {i}
      </div>
    );
  }

  return pageNumbers;
}

handleSearchChange = (e) => {
  const searchQuery = e.target.value; 
  this.setState({ searchQuery, currentPage: 1 }, () => {
    this._init(1);
  });
};

clearSearch = () => {
  this.setState({ searchQuery: '' }, () => {    
    this._init(1);
  });
};

isNextPageDisabled() {
  const { currentPage, totalPages, companyList } = this.state;
  return companyList.length < 10 && currentPage < totalPages;
}
_onLogout = () => {
  this.props.mainActions.checkOut((err, data) => {
      window.location.reload();
  });
};

emptyTeamOpen = () =>{
  this.setState({
    emptyTeam : true
  })
}

closeTeamModal = ()=>{
  this.setState({
    emptyTeam : false
  })
}

  render() {
    let { companyList,currentPage,activeMembers,totalMembers } = this.state;        
    console.log('******************************',this.state.companyTeamList);    
    let dataOption = [];

    if(this.state.companyTeamList) {
      this.state.companyTeamList.filter(i=>{dataOption.push({value: i.id, label:i.name});});
  }

  let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
    return (
      <div className="user-manage user_management_container">
        {this.state.isLoading && <div style={{position:'absolute',top:'0'}}> <Loaderview/> </div>}
         <Header isSearch={false}
                                 type={1} login={login} onLogout={this._onLogout}/>
                                 <div className="body_container">
        <div className="userheader">
          <h1>User Management</h1>
          <div className="user-search col-sm-6 display_row">
            <input
              className="text-box-search-team pt-3"
              placeholder="Search users"
              value={this.state.searchQuery} 
              onChange={this.handleSearchChange}
            />
            {this.state.searchQuery ? (
              <div onClick={this.clearSearch}>
                 <Image
                className="icon-search"
                src={require("../../assets/images/remove-user.png")}
              />
              </div>
            ) : (
              <Image
                className="icon-search"
                src={require("../../assets/images/search-icon.png")}
              />
            )}
          </div>
        </div> 
    <div className="team_sizeDiv">
        <div className="team-size">
        <p>Team Size: {activeMembers} of {totalMembers} seats used</p>
        </div>
                        <Modal
                            show={this.state.emptyTeam}
                            onHide={this.closeTeamModal}
                            className="remove-user"
                            >
                            <Modal.Body>            
                                <Image
                                className="logo"
                                src={require("../../assets/images/icon-member.png")}
                                />            
                                <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px" }}>
                                You cannot add a new member without creating a team. Please create a team first to proceed.         
                                </div>
                            </Modal.Body>
                         </Modal>
        
              <Button
                  className={"btn-edit-team btn-linear shadow_popup col-sm-1 new-member new-users"}
                  onClick={dataOption.length === 0 ? this.emptyTeamOpen : this.handleOpen} 
                  style={{ borderRadius: "25px", cursor: dataOption.length === 0 ? "not-allowed" : "pointer",  opacity: dataOption.length === 0 ? 0.5 : 1, 
                  }}>
                  ADD NEW
              </Button>

    </div>
        <div className="user_tables">
        
        {this.state.isLoading &&
                        <div className="display_row align-center justify-center data-loader" >
                            <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30}/>
                        </div>
                        }
                  {companyList && this.state.companyList && (      
          <table className="user-table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ width: "35%" }}></th>
                <th style={{ width: "20%" }}>Role</th>
                <th style={{ width: "20%" }}>Team</th>
                <th style={{ width: "25%" }}></th>
              </tr>
            </thead>
            <tbody className="table-body">
              {companyList.map((member) => (
                <tr key={member.user.id}>
                  <td className="Textlist user-detail">
                    <img
                      className="userimageEllipse"
                      alt=""
                      src={
                        member && member.user.avatar ? member.user.avatar : Config.avaDef
                      }
                    />
                    <span className="capitalize user-name">{member.user.fullName}</span>
                  </td>
                  <td className="Textlist">
                    {member.role.name}
                    </td>
                    <td className="Textlist">{(member && member.team && member.team.name 
                             ? (member.team.name.length > 15  ? member.team.name.substring(0, 15) + "..."   : member.team.name)  : '') }</td>
                  <td
                    onClick={() => {
                      if (member.role.slug !== "owner") {
                        this.openModal(member);
                      }
                    }}
                    className={`img-text ${
                      member.role.slug === "owner" ? "disabled" : ""
                    }`}
                  >
                    Remove
                    <div className="image-container">
                      <img
                        className="img-close"
                        alt=""
                        src={require("../../assets/images/remove-user.png")}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
                  )}
        </div>  
        <div>
       {companyList && companyList.length > 0 ? (        
        <div className={`paginations ${companyList.length < 10 && currentPage === 1 ? 'hidden' : ''}`}>
          <div className="arrow-icon" onClick={this.handlePreviousPage}>
            <img src={require("../../assets/images/header/btn_back.png")} alt="Previous" />
          </div>
          {this.renderPageNumbers()}
          <div className={`arrow-icon-right ${this.isNextPageDisabled() ? "disabled" : ""}`} onClick={this.handleNextPage}>
            <img src={require("../../assets/images/header/btn_back.png")} alt="Next" />
          </div>
        </div>   
          ) : !companyList && companyList.length < 0 ? (
            <p style={{textAlign : 'center'}}>No Company Member Found.</p>
          ) : ""}
        </div>   
        </div>  
        <Modal
          show={this.state.isRemoveMember}
          onHide={this.closeModal}
          className="remove-user"
        >
          <Modal.Body>
            
            <Image
              className="logo"
              src={require("../../assets/images/icon-member.png")}
            />
            <div className="head">Confirm</div>
            <div className="content" style={{ margin: "40px 70px 30px " }}>
              Are you sure you wish to remove {this.state.removeMemberName} from your account?
              Removing this person will revoke their access to the app and will
              delete all of their information within Teemie. This can not be
              undone. Your next invoice will reflect this change of team size.
            </div>
            <div className="display_row modal-footers" style={{ padding: "10px 0" , display: "flex" , justifyContent: "space-evenly"}}>
             

              <Button
                className="login_btn_login btn-linear-orange shadow_popup"
                style={{ maxHeight: 43, margin: 0 , borderRadius:"25px"}}
                onClick={this.closeModal}
              >
                CANCEL
              </Button>

              <Button
                className="login_btn_login btn-linear shadow_popup"
                style={{ maxHeight: 43, margin: "0" , borderRadius:"25px"}}
                onClick={() => {
                  this._removeMember(this.state.memberId);
                }}
              >
                CONFIRM
              </Button>


            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.isAddMember}
          onHide={this.handleClose}
          className="fix-modal add-member-popup"
        >
          <Image src={require('../../assets/images/btn-close-popup.png')} className="icon-close" onClick={this.handleClose}/>
                <Modal.Body>
                    <div className="title">Add Staff Member</div>
                    <div className="sub-title add-staff-sub-title">Enter the job title and email of the new team member here and they will recieve a link to register </div>
                    <CForm data={this.dataForm} ref="CForm"/>
                    <Select
                                options={dataOption} 
                                placeholder='Select Team'
                                className={`display_row form-input align-center userRoleSection`}
                                classNamePrefix="select"
                                defaultValue="staff"
                                closeMenuOnSelect={true}
                                onChange={this.onChangeSelect}
                            />
                    {this.state.messageInviteMember!=='' && (
                        <div className="register_error" style={{bottom:56, height:32, textAlign:'center', justifyContent:'center', alignItems:'flex-end', width:'100%'}}>
                            {this.state.messageInviteMember}
                        </div>
                    )}
                     {this.state.messageEvent!=='' && (
                        <div className="register_error" style={{width:'100%', bottom:60, textAlign:'center',alignItems:'flex-end'}}>
                            {this.state.messageEvent}
                        </div>
                    )}
                    <Button className="menu-left-action btn-linear shadow_popup btn-linear-invite" style={{borderRadius:"25px"}} onClick={this._addMember}>
                        SEND
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button>

                   
                </Modal.Body>
                
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    checkLogin: state.main.checkLogin,
    teamList: state.team.teamList,
    memberList: state.team.memberList,
    teamId: state.team.teamId,
  }),
  (dispatch) => ({
    userActions: bindActionCreators(userActions, dispatch),
    mainActions: bindActionCreators(mainActions, dispatch),
    memberActions: bindActionCreators(memberActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
    managementActions: bindActionCreators(managementActions, dispatch),
  })
)(UserManagementPage);
