import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./event_actions";
import Api from '../../services/api';

export function* getListEvent(data) {
    try {
        yield delay(300);
        let event = yield Api.put('/event/search', data.params);
        if(event && event.data){
            yield put({type: types.EVENT_LIST + '_SUCCESS', payload: event.data});
            data.cb && data.cb(null, event.data);
        }
        else{
            yield put({type: types.EVENT_LIST + '_SUCCESS', payload: []});
            data.cb && data.cb(event, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListEvent() {
    while (true){
        const watcher = yield takeLatest(types.EVENT_LIST,getListEvent);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* addEvent(data) {
    try {
        yield delay(300);
        let event = yield Api.put('/event/add', data.params);
        if(event && event.data){
            yield put({type: types.EVENT_ADD + '_SUCCESS', payload: event.data});
            data.cb && data.cb(null, event.data);
        }
        else{
            data.cb && data.cb(event, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchAddEvent() {
    while (true){
        const watcher = yield takeLatest(types.EVENT_ADD,addEvent);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* editEvent(data) {
    try {
        yield delay(300);
        console.log("----data.params",data.params);
        let event = yield Api.put('/event/edit', data.params);
        if(event && event.data){
            yield put({type: types.EVENT_EDIT + '_SUCCESS', payload: event.data});
            data.cb && data.cb(null, event.data);
        }
        else{
            data.cb && data.cb(event, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchEditEvent() {
    while (true){
        const watcher = yield takeLatest(types.EVENT_EDIT,editEvent);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* summaryEvent(data) {
    try {
        yield delay(300);
        let event = yield Api.put('/event/summary', data.params);
        if(event && event.data){
            yield put({type: types.SUMMARY_EVENT + '_SUCCESS', payload: event.data});
            data.cb && data.cb(null, event.data);
        }
        else{
            data.cb && data.cb(event, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchSummaryEvent() {
    while (true){
        const watcher = yield takeLatest(types.SUMMARY_EVENT,summaryEvent);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* removeEvent(data) {
    try {
        yield delay(300);
        let events = yield Api.put('/event/del', data.params);
        if(events?.data){
            yield put({type: types.EVENT_DELETE + '_SUCCESS', payload: events.data});
            data.cb?.(null, events.data);
        }
        else{
            data.cb?.(events, null);
        }
    }
    catch (e) {
        console.log("----eee :",e)
        data.cb?.(null, null);

    }
}

export function* watchRemoveEvent() {
    while (true){
        const watcher = yield takeLatest(types.EVENT_DELETE,removeEvent);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
