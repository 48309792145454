import  firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

export const fbInitializeApp = () => {
    console.log('====== fbInitializeApp ========');

    var config = {
        apiKey: process.env.REACT_APP_FIREBASE_NOTIFICATION_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STROAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };
    firebase.initializeApp(config);
};

export const fbRequestPermission = async (cb) => {
    console.log('====== fbRequestPermission ========');

    try {
        if (!firebase.messaging.isSupported()) {
            return;
        }

        const messaging = firebase.messaging();
        //await messaging.requestPermission();
        const token = await messaging.getToken();

        if (cb) cb(null, token);
        return token;
    } catch (error) {
        if (cb) cb(error);
        console.error(error);
    }
};

export const fbOnMessage = (cb) => {
    console.log('====== fbOnMessage ========');

    try {
        if (!firebase.messaging.isSupported()) {
            return;
        }

        const messaging = firebase.messaging();

        messaging.onMessage(function(payload) {

            if (cb) cb(null, payload);
        });
    } catch (error) {
        if (cb) cb(error);
        console.error(error);
    }
};
